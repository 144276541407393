import useSearchParams from "hooks/useSearchParams";
import React from "react";

const SsoLogin = () => {
	const { getParam } = useSearchParams();
	const token = getParam("token");

	return <main className="min-h-screen">SsoLogin {token}</main>;
};

export default SsoLogin;
