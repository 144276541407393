import PaddingX from "layouts/PaddingX";
import Smartlearning from "../../../assets/images/smartlearning.png";

const products = [
	{
		link: "https://smartlearning.akello.co",
		image: Smartlearning,
		label: "Smart Learning",
	},
];

const SlideItem3 = () => {
	return (
		<div className="flex items-center justify-center w-full h-full  bg-slide-banner3 bg-no-repeat bg-cover">
			<PaddingX>
				<h1 className="text-white font-cera-black text-center slider-title mb-7">
					Akello Products
				</h1>

				<div className="w-full flex justify-center mb-5 ">
					{products.map(({ link, image, label }, index) => (
						<div
							key={index}
							// className="product-item"
						>
							<a
								className="flex flex-col items-center w-full"
								href={link}
								target="__blank"
							>
								<img
									src={image}
									alt=""
									style={{ width: 150, height: 150 }}
								/>
								<span className="text-white text-base md:text-lg max-w-max">
									{label}
								</span>
							</a>
						</div>
					))}
				</div>

				<a
					href="https://www.akello.co"
					target="_blank"
					className="bg-white text-akgreenest text-lg px-12 py-2  hover:bg-akgreenest hover:text-white rounded-full"
					style={{ transition: "0.5s" }}
					rel="noreferrer"
				>
					Explore
				</a>
			</PaddingX>
		</div>
	);
};

export default SlideItem3;
