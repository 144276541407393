import React from "react";
import ProfileLayout from "../ProfileLayout";
import useSearchParams from "hooks/useSearchParams";
import useProfile from "api/UserResource/useProfile";
import useAuthStore from "store/authStore";
import UpdatePublisher from "../UpdatePublisher";
import { Role } from "utils/roles";

const PublisherDetails = () => {
	const { getParam } = useSearchParams();
	const heading = getParam("heading") || "Publisher details";

	const { hasRoles } = useAuthStore();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<ProfileLayout
			heading={heading}
			isLoading={isLoadingProfile}
			profile={profile?.data}
		>
			<div>
				{hasRoles([Role.SENIOR_PUBLISHER]) && (
					<div>
						<UpdatePublisher
							publisherId={profile?.data?.publishers[0]?.id}
							name={profile?.data?.publishers[0]?.name}
							physicalAddress={profile?.data?.publishers[0]?.physical_address}
							focusArea={profile?.data?.publishers[0]?.focus_area}
							country={profile?.data?.publishers[0]?.country}
						/>
					</div>
				)}
			</div>
		</ProfileLayout>
	);
};

export default PublisherDetails;
