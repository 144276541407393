import useUpdatePublisher from "api/PublisherResource/useUpdatePublisher";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import useSearchParams from "hooks/useSearchParams";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { MAX_PDF_SIZE } from "utils";
import { countries } from "utils/data";
import * as Yup from "yup";

const UpdatePublisher = ({
	publisherId,
	name,
	physicalAddress,
	focusArea,
	country,
}) => {
	const { getParam } = useSearchParams();

	const returnUrl = getParam("returnUrl") || "";
	const history = useHistory();

	const initialValues = {
		name: name,
		country: country || "",
		physical_address: physicalAddress || "",
		focus_area: focusArea || "",
		tax_clearance: null,
		certificate_of_incorporation: null,
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of publishing company is required"),
		physical_address: Yup.string().required("Address is required"),
		country: Yup.string().required("Country is required"),
		tax_clearance: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`File size should not exceed ${MAX_PDF_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_PDF_SIZE;
				}
			)
			.test("fileMimeType", "Unsupported file format", (value) => {
				if (!value) return true;
				return [
					"application/pdf",
					"image/jpeg",
					"image/png",
					"image/svg+xml",
				].includes(value.type);
			}),
		certificate_of_incorporation: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`File size should not exceed ${MAX_PDF_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_PDF_SIZE;
				}
			)
			.test("fileMimeType", "Unsupported file format", (value) => {
				if (!value) return true;
				return [
					"application/pdf",
					"image/jpeg",
					"image/png",
					"image/svg+xml",
				].includes(value.type);
			}),
	});

	const { mutateAsync, isLoading } = useUpdatePublisher(publisherId);

	const handleSave = async (data) => {
		await mutateAsync(data);
		if (returnUrl) {
			history.push(returnUrl);
		}
	};

	return (
		<>
			<div>
				<p className="text-base">
					Update details for <span className="font-bold">{name}</span>
				</p>
				<p>Fields marked (*) are required</p>

				<div className="mt-4 pt-4">
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(data) => handleSave(data)}
					>
						{({ setFieldValue, values }) => (
							<Form className="space-y-8">
								<FormField
									name="name"
									label="Name of Publishing Company *"
								/>

								<FormField
									name="physical_address"
									label="Physical Address *"
								/>

								<FormField
									type="select"
									name="country"
									label="Country*"
									options={countries.map((country) => ({
										label: country.name,
										value: country.name,
									}))}
								/>

								<FormField
									type="file"
									name="tax_clearance"
									value={null}
									label="Tax Clearance"
									style={{
										paddingLeft: `${!values?.tax_clearance ? "120px" : ""} `,
									}}
									onChange={(event) =>
										setFieldValue("tax_clearance", event.target.files[0])
									}
								/>

								<FormField
									type="file"
									name="certificate_of_incorporation"
									value={null}
									label="Certifiate of Incorporation"
									style={{
										paddingLeft: `${
											!values?.certificate_of_incorporation ? "190px" : ""
										} `,
									}}
									onChange={(event) =>
										setFieldValue(
											"certificate_of_incorporation",
											event.target.files[0]
										)
									}
								/>

								<FormField
									name="focus_area"
									label="What's your focus area?"
								/>

								<div className="mt-4 flex items-center">
									<button
										type="submit"
										className="py-3 px-3 bg-akgreen w-full rounded-full text-white flex items-center justify-center gap-2 max-w-max"
									>
										Update details
										{isLoading && (
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										)}
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};

export default UpdatePublisher;
