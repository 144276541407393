import useProfile from "api/UserResource/useProfile";
import React from "react";
import ProfileLayout from "../ProfileLayout";
import {
	activeCurrencies,
	activePaymentMethods,
	getActiveCurrencySymbol,
	getPaymentMethodLabel,
} from "utils";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useFetchSubscriptions from "api/SubscriptionsResource/useFetchSubscriptions";
import useApplyAutoDeduction from "api/UserResource/useApplyAutoDeduction";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useCancelAutoDeductions from "api/UserResource/useCancelAutoDeductions";

const AutoDeductions = () => {
	const { data: subscriptions, isLoading: isLoadingSubscriptions } =
		useFetchSubscriptions();
	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	const recurringSubscription = profile?.data?.recurred_subscription;

	const subscriptionChoices =
		subscriptions?.data?.map((subscription) => ({
			label: `${subscription?.name} - (${subscription?.duration} ${subscription?.time_unit})`,
			value: subscription.id,
		})) || [];

	const currencies = [
		{ label: activeCurrencies.USD.label, value: activeCurrencies.USD.code },
		{ label: activeCurrencies.ZWL.label, value: activeCurrencies.ZWL.code },
	];

	const initialValues = {
		subscription: "",
		currency: "",
		mobile_number: "",
	};

	const validate = Yup.object().shape({
		mobile_number: Yup.string()
			.typeError("Mobile number must be numeric")
			.min(9, "Mobile number must be 9 digits and above")
			.required("EcoCash mobile number for auto-deduction is required"),
		currency: Yup.string().required("Currency is required"),
		// subscription: Yup.string().required("Subscription is required"),
	});

	const { mutateAsync, isLoading: isApplyingAutoDeduction } =
		useApplyAutoDeduction();

	async function applyAutoDeduction(values) {
		const data = {
			...values,
			payment_method:
				values?.currency === "USD"
					? activePaymentMethods.ecocashUsd.name
					: activePaymentMethods.ecocashZwl.name,
		};
		await mutateAsync(data);
	}

	const { mutateAsync: cancelAutoDeduction, isLoading: isCancelling } =
		useCancelAutoDeductions();

	async function handleCancelAutoDeduction() {
		await cancelAutoDeduction();
	}

	// console.log({ recurringSubscription });

	return (
		<ProfileLayout
			heading={"Auto deductions"}
			isLoading={isLoadingProfile || isLoadingSubscriptions}
			profile={profile?.data}
		>
			<div className="space-y-4">
				<p className="px-4 py-3 rounded-lg bg-gray-100">
					Auto deductions allow your subscription to renew automatically by
					deducting the subscription amount from your EcoCash wallet when your
					active subscription expires.
				</p>

				{recurringSubscription && (
					<div className="py-3 rounded-lg bg-gray-100 space-y-2 px-4">
						<p className="text-sm ">
							Your account is currently set to auto-deduct{" "}
							<span className="font-bold">
								{recurringSubscription?.pivot?.currency}
								{getActiveCurrencySymbol(
									recurringSubscription?.pivot?.currency
								)}{" "}
								{recurringSubscription?.pivot?.currency === "USD"
									? recurringSubscription?.cost
									: recurringSubscription?.zwl_price}
							</span>{" "}
							from your{" "}
							<span className="font-bold">
								{getPaymentMethodLabel(
									recurringSubscription?.pivot?.payment_method
								)}
							</span>{" "}
							wallet every{" "}
							<span className="font-bold">
								{recurringSubscription?.duration}{" "}
								{recurringSubscription?.time_unit}{" "}
							</span>{" "}
							after your subscription lapses
						</p>

						<div className="flex items-center gap-2 justify-between">
							<p>Do you want to cancel auto-deductions?</p>
							<button
								type="button"
								onClick={() => handleCancelAutoDeduction()}
								className="py-3 px-3 bg-akgreen w-36 rounded-full text-white flex items-center justify-center gap-2 "
							>
								Yes
								{isCancelling && (
									<AiOutlineLoading3Quarters className="animate-spin" />
								)}
							</button>
						</div>
					</div>
				)}

				<div className="pt-4">
					<p className="text-lg">Apply/Update auto deduction settings</p>
					<p>
						Please choose the the subscription plan that you want to apply
						auto-deductions to.
					</p>

					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(data) => applyAutoDeduction(data)}
					>
						{(formik) => (
							<Form className="mt-6 space-y-6">
								<FormField
									autoFocus
									label={"EcoCash mobile number * (e.g 0783580001)"}
									type="number"
									name="mobile_number"
								/>

								<FormField
									type="select"
									name="currency"
									label="Currency *"
									options={currencies}
								/>

								<FormField
									type="select"
									name="subscription"
									label="Subscription to apply auto-deduction *"
									options={subscriptionChoices}
								/>

								<div className="mt-4 flex items-center">
									<button
										type="submit"
										className="py-3 px-3 bg-akgreen w-full rounded-full text-white flex items-center justify-center gap-2  "
									>
										Save
										{isApplyingAutoDeduction && (
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										)}
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</ProfileLayout>
	);
};

export default AutoDeductions;
