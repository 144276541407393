import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
// import { delay, fetchConfig } from "utils";

const useGuards = () => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get("/guards");
		return res?.data?.data;
	};

	const TIME_TO_REFETCH = 5000;

	return useQuery(["guards"], () => fetch(), {
		staleTime: TIME_TO_REFETCH,
		retry: 2,
		refetchInterval: TIME_TO_REFETCH,
		refetchIntervalInBackground: true,
	});
};

export default useGuards;
