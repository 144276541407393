import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
// import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
// import useOverlayStore from "store/overlayStore";
import { deleteEmptyObjects } from "utils";

const useEcocashPaySubscription = (setPaymentIsSuccessful = null) => {
	const { accessToken } = useTokenStore();

	const queryClient = useQueryClient();

	const history = useHistory();
	const location = useLocation();
	const retryUrl = location.pathname;

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });

		const body = deleteEmptyObjects(obj);

		const response = await instance.post(
			`/subscriptions/subscribe/${body?.id}`,
			body,
			{
				timeout: 10000000,
				timeoutErrorMessage: "Connection timed out...",
			}
		);
		return response?.data;
	};

	return useMutation(save, {
		onSuccess: async ({ data }) => {
			queryClient.invalidateQueries("is_subscribed");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("purchased");
			queryClient.invalidateQueries("subscribedBooks");
			queryClient.invalidateQueries("profile");

			if (data?.status === "Failed") {
				const queryString = `retryUrl=${retryUrl}&order_id=${data?.id}&ecocash_reference=${data?.ecocash_reference}&reference=${data?.reference}&phone=${data?.phone}&has_picks=${data?.has_picks}`;
				history.push(`/subscription-response?${queryString}`);
			} else {
				const queryString = `payment_method=${data?.payment_method}&has_picks=${data?.has_picks}`;
				history.push(`/subscription-success-response?${queryString}`);
			}
		},
		onError: async (error) => {
			queryClient.invalidateQueries("is_subscribed");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("purchased");
			queryClient.invalidateQueries("subscribedBooks");

			const errorMsg =
				error?.response?.data?.message ||
				"Something went wrong. Please try again later.";
			const queryString = `retryUrl=${retryUrl}&payment_method=EcoCash&error=${errorMsg}`;
			history.push(`/subscription-error-response?${queryString}`);
		},
	});
};

export default useEcocashPaySubscription;
