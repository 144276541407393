import usePublisherPurchasedBooks from "api/BookResource/usePublisherPurchasedBooks";
import Admin2 from "layouts/Admin2";
import React, { useState } from "react";
import PurchasedBooksLoader from "./PurchasedBooksLoader";
import usePurchasedBooksFilters from "./usePurchasedBooksFilters";
import { activeCurrencies, deleteEmptyObjects } from "utils";
import { Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { BsFilterRight } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { CustomPaginator } from "components";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableView from "./TableView";

const PublisherPurchasedBooks = () => {
	const filters = usePurchasedBooksFilters();

	const [dataView, setDataView] = useState("table"); // grid or table

	const initialValues = {
		searchTerm: filters.searchTerm,
		categoryIs: filters.categoryIs,
		page: filters.page,
		rowsPerPage: filters.rowsPerPage,
		firstPage: filters.firstPage,
		fromDate: filters.fromDate,
		toDate: filters.toDate,
		revenueLine: filters.revenueLine,
	};

	const handleSubmit = (values) => {
		const params = {
			first_page: values.firstPage,
			rows: values.rowsPerPage,
			page: values.page,
			category_is: values.categoryIs,
			search_term: values.searchTerm,
			from_date: values.fromDate,
			to_date: values.toDate,
			revenue_line: values.revenueLine,
		};
		const queryParams = deleteEmptyObjects(params);
		filters.changeAllFiltersAtOnce(queryParams);
	};

	const { data: books, isLoading } = usePublisherPurchasedBooks({
		page: filters.page,
		rows_per_page: filters.rowsPerPage,
		from_date: filters.fromDate,
		to_date: filters.toDate,
		searchTerm: filters.searchTerm,
		revenue_line: filters.revenueLine,
		category_is: filters.categoryIs,
	});

	return (
		<Admin2>
			<div className="mt-4 w-full space-y-4 flex flex-col flex-1">
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{({ values, setFieldValue }) => {
						const handleClearFilters = () => {
							setFieldValue("rowsPerPage", 50);
							setFieldValue("page", "");
							setFieldValue("categoryIs", "");
							setFieldValue("searchTerm", "");
							setFieldValue("fromDate", "");
							setFieldValue("toDate", "");
							setFieldValue("revenueLine", "purchase");

							const params = {
								first_page: "",
								rows: "50",
								page: "1",
								category_is: "",
								search_term: "",
								from_date: "",
								to_date: "",
								revenue_line: "purchase",
							};
							const queryParams = deleteEmptyObjects(params);
							filters.changeAllFiltersAtOnce(queryParams);
						};

						const handleChangeRowsPerPage = (val) => {
							setFieldValue("rowsPerPage", val);
							filters.changeRowsPerPage(val);
						};

						const handleChangeRevenueLine = (value) => {
							setFieldValue("revenueLine", value);
							filters.changeRevenueLine(value);
						};

						return (
							<div className="space-y-4">
								<div className="flex flex-col">
									<h4>
										List of books sold through{" "}
										{filters?.revenueLine === "purchase"
											? "purchase"
											: "subscription"}{" "}
										model
									</h4>

									<div className="flex gap-2 items-center">
										<p>Showing</p>
										<select
											name="rowsPerPage"
											className="w-14 h-12 px-2 rounded bg-gray-100 border border-gray-300"
											value={values.rowsPerPage}
											onChange={(e) => handleChangeRowsPerPage(e.target.value)}
											defaultValue={values.rowsPerPage}
										>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="40">40</option>
											<option value="50">50</option>
										</select>
										<p>books maximum</p>
									</div>
								</div>

								<div className="flex justify-between items-center">
									<Form className="flex justify-between  gap-2">
										<div className="flex items-center justify-end gap-2">
											<div className="flex items-center">
												{[
													{ label: "Purchased", model: "purchase" },
													{ label: "Subscribed", model: "subscription" },
												]?.map((revenueLine, index) => {
													const classNameX =
														index === 0
															? "rounded-l border-l"
															: "rounded-r border-r";

													return (
														<label
															key={index}
															htmlFor={`model_${revenueLine.model}`}
															className={` cursor-pointer max-w-max h-10 px-2  border-t border-b ${classNameX}  flex items-center justify-between gap-1 ${
																values.revenueLine === revenueLine.model
																	? "bg-akgreener border-akgreener text-white"
																	: "bg-gray-100 border-gray-300"
															} `}
														>
															<p className="flex flex-col gap-0">
																<span>{revenueLine.label}</span>
															</p>
															<input
																id={`model_${revenueLine.model}`}
																type="radio"
																name="revenueLine"
																value={revenueLine.model}
																hidden
																onChange={(e) => {
																	handleChangeRevenueLine(e.target.value);
																}}
																checked={
																	values.revenueLine === revenueLine.model
																}
															/>
														</label>
													);
												})}
											</div>

											<input
												type="text"
												name="searchTerm"
												value={values.searchTerm}
												onChange={(e) =>
													setFieldValue("searchTerm", e.target.value)
												}
												className="w-44 h-10 border border-gray-300  rounded px-3"
												placeholder="Search author, title..."
											/>

											<select
												name="categoryIs"
												className="w-44 h-10 border border-gray-300  rounded px-3 bg-white"
												value={values.categoryIs}
												onChange={(e) =>
													setFieldValue("categoryIs", e.target.value)
												}
											>
												<option label="- Select category -"></option>
												<option value="Academic">Academic</option>
												<option value="Non-academic">Non-academic</option>
											</select>

											<Calendar
												value={new Date(values.fromDate)}
												name="fromDate"
												dateFormat="yy-mm-dd"
												placeholder="Date (from)"
												className="w-44 h-10 font-sans"
												inputClassName="border border-gray-300 bg-white rounded"
												showIcon
												readOnlyInput
												showButtonBar
												onChange={(e) =>
													setFieldValue(
														"fromDate",
														moment(e.target.value).format("YYYY-MM-DD")
													)
												}
												onClearButtonClick={() => setFieldValue("fromDate", "")}
											/>

											<Calendar
												value={new Date(values.toDate)}
												name="toDate"
												dateFormat="yy-mm-dd"
												placeholder="Date (to)"
												className="w-44 h-10 font-sans"
												inputClassName="border border-gray-300 bg-white rounded"
												showIcon
												readOnlyInput
												showButtonBar
												onChange={(e) =>
													setFieldValue(
														"toDate",
														moment(e.target.value).format("YYYY-MM-DD")
													)
												}
												onClearButtonClick={() => setFieldValue("toDate", "")}
											/>

											<div className="flex items-center justify-end gap-2">
												<button
													type="submit"
													className="h-10 px-2 py-2 bg-akgreener rounded text-white flex items-center gap-1"
												>
													<span>Filter</span>
													<BsFilterRight size={20} />
												</button>

												<button
													type="button"
													onClick={handleClearFilters}
													className="flex items-center h-10 border border-akgreener rounded px-2"
												>
													<span>Clear filters</span>
													<FiX size={20} />
												</button>
											</div>
										</div>
									</Form>

									<div className="flex justify-end  ">
										<button
											className={`rounded-l-lg h-10 border px-2 py-2 border-akgreen ${
												dataView === "grid" ? "bg-akgreener text-white" : ""
											}  `}
											type="button"
											onClick={() => setDataView("grid")}
										>
											Grid view
										</button>
										<button
											className={`rounded-r-lg h-10 border border-l-0 px-2 py-2 border-akgreen ${
												dataView === "table" ? "bg-akgreener text-white" : ""
											} `}
											type="button"
											onClick={() => setDataView("table")}
										>
											Table view
										</button>
									</div>
								</div>
							</div>
						);
					}}
				</Formik>

				<div className="pt-4">
					{(function () {
						if (isLoading) {
							return (
								<div className="grid grid-cols-4 gap-y-4 gap-x-4 ">
									{Array.from({ length: 8 }, (_, index) => (
										<PurchasedBooksLoader key={index} />
									))}
								</div>
							);
						}

						if (dataView === "table") {
							return (
								<TableView
									books={books?.data?.records}
									filters={filters}
								/>
							);
						}

						return (
							<div className="grid grid-cols-4 gap-y-4 gap-x-4 ">
								{books?.data?.records?.map((book) => (
									<div
										key={book.id}
										// style={{ width: 350 }}
										className="border border-gray-300 flex gap-4 py-4 px-3 rounded bg-gray-100 max-w-max"
									>
										<div
											style={{
												width: 150,
												height: 180,
												backgroundImage: `url(${book?.book_cover?.url})`,
												backgroundPosition: "center",
												backgroundSize: "cover",
												backgroundRepeat: "no-repeat",
											}}
											className="rounded"
										/>

										<div
											className="py-3 flex flex-col gap-1"
											style={{ width: 180 }}
										>
											<p
												className="truncate"
												title={book?.author}
											>
												Author: {book?.author}
											</p>
											<p
												className="truncate"
												title={book?.title}
											>
												Title: {book?.title}
											</p>
											<p className=" rounded-full max-w-max font-bold">
												{book?.category?.name}
											</p>
											<p
												title={book?.genres[0]?.name}
												className="truncate border border-gray-500 rounded-full px-2 max-w-max"
											>
												{book?.genres[0]?.name}
											</p>

											<div className="pt-1">
												<h4>Purchase details</h4>
												<div className="flex items-center gap-2">
													<span>
														Copies{" "}
														{filters.revenueLine === "purchase"
															? "purchased"
															: "subscribed"}
													</span>
													<span className="text-2xl font-bold">
														{filters.revenueLine === "purchase"
															? book?.number_of_copies_purchased
															: book?.number_of_copies_subscribed}
													</span>
												</div>
												<div className="flex flex-col justify-center ">
													<h4>Book sales </h4>
													<div className="flex gap-2">
														<span className="text-md font-bold">
															{activeCurrencies.USD.label}${book?.sales?.usd}
														</span>
														|
														<span className="text-md font-bold">
															{activeCurrencies.ZWL.label}${book?.sales?.zwl}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						);
					})()}
				</div>

				{!isLoading && (
					<div className="flex items-center gap-3 mt-4">
						<p>Total number of books: {books?.data?.total_records}</p>
					</div>
				)}

				<div className="flex-1 flex items-end pb-4">
					<CustomPaginator
						firstPage={filters.firstPage}
						setFirstPage={filters.changeFirstPage}
						rowsPerPage={filters.rowsPerPage}
						setRowsPerPage={filters.changeRowsPerPage}
						totalRecords={books?.data?.total_records}
						setPage={filters.changePage}
						showRowsPerPageDropdown={false}
					/>
				</div>
			</div>
		</Admin2>
	);
};

export default PublisherPurchasedBooks;
