import React from "react";
import { countries } from "utils/data";

const HorizontalBookFilter = ({ filters, publishers, genres, categories }) => {
	return (
		<div className="flex items-center gap-1">
			<select
				name="publisherIs"
				value={filters?.publisherIs}
				onChange={(e) => filters.changePublisherIs(e.target.value)}
				className="w-full h-10 rounded-lg px-2 bg-gray-100 outline-none border border-transparent"
			>
				<option label="- Filter by publisher -"></option>
				{publishers?.map((publisher) => (
					<option
						key={publisher?.id}
						value={publisher.id?.toString()}
					>
						{publisher.name}
					</option>
				))}
			</select>

			<select
				name="categoryIs"
				value={filters?.categoryIs}
				onChange={(e) => filters.changeCategoryIs(e.target.value)}
				className="w-full h-10 rounded-lg px-2 bg-gray-100 outline-none border border-transparent"
			>
				<option label="- Filter by category -"></option>
				{categories?.map((category) => (
					<option
						key={category?.name}
						value={category.name}
					>
						{category.name}
					</option>
				))}
			</select>

			<select
				name="pricingModelIs"
				value={filters?.pricingModelIs}
				onChange={(e) => filters.changePricingModelIs(e.target.value)}
				className="w-full h-10 rounded-lg px-2 bg-gray-100 outline-none border border-transparent"
			>
				<option label="- Filter by pricing model -"></option>
				<option value="">All books</option>
				<option value="has_purchase">Available for purchase</option>
				<option value="has_subscription">Available for subscription</option>
				<option value="free">Free books</option>
			</select>

			<select
				name="countryIs"
				value={filters?.countryIs}
				onChange={(e) => filters.changeCountryIs(e.target.value)}
				className="w-full h-10 rounded-lg px-2 bg-gray-100 outline-none border border-transparent"
			>
				<option label="- Filter by country -"></option>
				{countries?.map((country, index) => (
					<option
						key={index}
						value={country.name}
					>
						{country.name}
					</option>
				))}
			</select>
		</div>
	);
};

export default HorizontalBookFilter;
