import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { activeCurrencies } from "utils";
// import { activeCurrencies } from "utils";

const TableView = (props) => {
	const { books, filters } = props;

	const imgTemplate = (rowData) => {
		return (
			<img
				src={rowData?.book_cover?.url}
				alt={rowData?.title}
				className="w-20 h-20 rounded-full object-cover"
			/>
		);
	};

	const usdSalesTemplate = (rowData) => <span>${rowData?.sales?.usd}</span>;
	const zigSalesTemplate = (rowData) => <span>${rowData?.sales?.zwl}</span>;
	const copiesTemplate = (rowData) => (
		<span>
			{filters.revenueLine === "purchase"
				? rowData?.number_of_copies_purchased
				: rowData?.number_of_copies_subscribed}
		</span>
	);
	const categoryTemplate = (rowData) => <span>{rowData?.category?.name}</span>;
	const collectionTemplate = (rowData) => (
		<span>{rowData?.genres[0]?.name}</span>
	);

	return (
		<DataTable
			value={books}
			dataKey="id"
			tableClassName="font-sans text-sm"
			tableStyle={{ width: "100%" }}
		>
			<Column
				headerClassName="text-center"
				body={imgTemplate}
			/>
			<Column
				header="Book title"
				field="title"
			/>
			<Column
				header="Author(s)"
				field="author"
			/>
			<Column
				header="Category"
				body={categoryTemplate}
				style={{ width: 130 }}
			/>
			<Column
				header="Collection"
				body={collectionTemplate}
			/>
			<Column
				header={`Copies ${
					filters.revenueLine === "purchase" ? "purchased" : "subscribed"
				}`}
				body={copiesTemplate}
				style={{ width: 150 }}
			/>
			<Column
				header={`Sales (${activeCurrencies.USD.label})`}
				body={usdSalesTemplate}
				style={{ width: 120 }}
			/>
			<Column
				header={`Sales (${activeCurrencies.ZWL.label})`}
				body={zigSalesTemplate}
				style={{ width: 120 }}
			/>
		</DataTable>
	);
};

export default TableView;
